import { useEffect } from "react";
// import { deleteAllCookies } from "../utils/deleteAllCookies";

/**
 * Calling this removes persisted trolley on browse exit
 */
export const useBrowserCloseEffect = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", function () {
      // deleteAllCookies();
    });
  }, []);
};
